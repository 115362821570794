import { render, staticRenderFns } from "./ZCCity.vue?vue&type=template&id=1e70e122&scoped=true&"
import script from "./ZCCity.vue?vue&type=script&lang=ts&"
export * from "./ZCCity.vue?vue&type=script&lang=ts&"
import style0 from "./ZCCity.vue?vue&type=style&index=0&id=1e70e122&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e70e122",
  null
  
)

export default component.exports